import selectAll, { selectOne } from '../../helpers/jquery-replace';

const updateIndicator = (el: HTMLElement) => {
    const indicator = selectOne('.nav-main__link-indicator') as HTMLElement;
    if (indicator) {
        indicator.style.left = el.offsetLeft + 'px';
    }
};

const makeParentIndicator = (el: HTMLElement) => {
    const template = document.createElement('template');
    template.innerHTML = '<span class=\'nav-main__link-indicator u-hide-for-small\' style=\'left:' + el.offsetLeft + 'px; width:' + el.offsetWidth + 'px;\'></span>';
    const globalHeader = selectOne('.global-header') as HTMLElement;
    if (globalHeader) {
        globalHeader.appendChild(template.content);
    }
};

export default class Menu {
    constructor(element: HTMLElement) {
        // Height of header
        const headerHeightElement = document.getElementById('_js-global-header__content');
        const headerHeight = headerHeightElement ? headerHeightElement.offsetHeight : 0;

        const onLoad = () => {
            if (headerHeight != null) {
                (selectOne('body') as HTMLElement).style['--headerHeight'] = headerHeight;
            }
        };

        if (document.readyState != 'loading'){
            onLoad();
        } else {
            document.addEventListener('DOMContentLoaded', onLoad);
        }
    
        // Toogle mega menu desktop hover
        const mainItems = selectAll('._js-nav-main-item');
        mainItems.forEach((el: HTMLElement) =>  {
            const mq = window.matchMedia('(min-width: 64.0625em)');
            const menuTrigger = el.querySelector('._js-nav-main-expander') as HTMLElement;
            const menuToExpand = el;
            const menuToExpandContainer = el.querySelector('._js-mega-menu-container') as HTMLElement;

            if (mq.matches) {
                // Mouseovers
                menuTrigger.addEventListener('mouseenter', (e: Event) => {
                    e.preventDefault();
                    selectAll('._js-nav-main-item').forEach((e: HTMLElement) => e.classList.remove('is-expanded'));
                    menuToExpand.classList.add('is-expanded');
                });
                menuToExpandContainer.addEventListener('mouseleave', () => {
                    menuToExpand.classList.remove('is-expanded');
                });
            }
        });
        
        // Toggle Mobile menu
        selectAll('._js-mobile-menu-trigger').forEach((e: HTMLElement) => {
            e.addEventListener('click', () => {
                element.classList.toggle('is-active');
                (selectOne('body') as HTMLElement).classList.toggle('u-overflow-hidden');
            });
        });
        
        // Toggle mobile submenus
        const mobileExpanders = selectAll('._js-nav-main-expander');
        mobileExpanders.forEach( (expander: HTMLElement) => {
            expander.addEventListener('click', e => {
        
                //MObil
                const mq = window.matchMedia('(max-width: 64.0625em)');
                if (mq.matches) {
                    e.preventDefault();
                    (expander.closest('._js-nav-main-item') as HTMLElement).classList.toggle('is-expanded');
                }
                
            });
        });
        
        if (selectAll('.nav-main__link.self').length > 0) {
            const menuElement = selectOne('.nav-main__link.self') as HTMLElement;
            makeParentIndicator(menuElement);
        
            window.onresize = () => {
                updateIndicator(menuElement);
            };
        } else if (selectAll('.nav-main__link.on-path').length > 0) {
            const menuElement = selectOne('.nav-main__link.on-path') as HTMLElement;
            makeParentIndicator(menuElement);
            window.onresize = () => {
                updateIndicator(menuElement);
            };
        }
    }
}
