// lazy.ts
export function lazy(
    callBack: () => void,
    target: string | Element = ".product-list",
    onlyOnce = false,
): { disconnect: () => void } {
    let lazyObserver: IntersectionObserver | null = null;
    let hasIntersected = false;

    const init = () => {
        if (!("IntersectionObserver" in window)) {
            callBack();
            return;
        }

        const element = typeof target === "string"
            ? document.querySelector(target)
            : target;

        if (!element) {
            return;
        }

        lazyObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting && shouldCallCallback()) {
                        hasIntersected = true;
                        callBack();
                    }
                });
            },
            { threshold: [0] },
        );

        lazyObserver.observe(element as Element);
    };

    const disconnect = () => {
        if (lazyObserver) {
            lazyObserver.disconnect();
        }
    };

    const shouldCallCallback = () => {
        return !onlyOnce || !hasIntersected;
    }

    init();

    return {
        disconnect,
    };
}